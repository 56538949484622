<template>
  <div class="home">
    <div>
      <!-- 走马灯 -->
      <el-carousel
        trigger="click"
        height="45.8vw"
        :interval="6000"
        arrow="always"
        @change="handleChange"
      >
        <el-carousel-item v-for="(item, index) in owsTop2" :key="item.id">
          <img
            style="height: 45.8vw; width: 100vw"
            :src="item.imageUrl"
            alt=""
          />
          <div class="carousel">
            <h3
              :class="
                isActive == index ? 'animate__animated animate__fadeInUp ' : ''
              "
            >
              {{ item.title }} <span>{{ item.product1 }}</span>
            </h3>
            <p
              :class="
                isActive == index ? 'animate__animated animate__fadeInUp ' : ''
              "
              class="animate__delay-1s"
              style="font-size: 1.5625vw; margin: 1.0417vw 0"
            >
              {{ item.content }}
            </p>
            <el-button
              :class="
                isActive == index ? 'animate__animated animate__fadeInUp ' : ''
              "
              @click="getShopp(item)"
              size=""
              plain
              round
              style="
                background-color: transparent;
                color: white;
                font-weight: 600;
              "
              class="hidden-sm-and-down animate__delay-1s"
              >查看详细 <i class="el-icon-right"></i
            ></el-button>
            <el-button
              :class="
                isActive == index ? 'animate__animated animate__fadeInUp ' : ''
              "
              @click="getShopp(item)"
              class="hidden-md-and-up animate__delay-1s"
              style="
                margin-top: 0.625rem;
                background-color: transparent;
                color: white;
                font-weight: 600;
              "
              size="mini"
              plain
              round
              >查看详细 <i class="el-icon-right"></i
            ></el-button>
          </div>
        </el-carousel-item>
      </el-carousel>
      <!-- recommendation -->
      <div class="substance1">
        <div class="warp">
          <h3 class="h3">精品推荐<span>Recommend</span></h3>
          <div class="product">
            <div class="recommend" v-for="item in substance1" :key="item.id">
              <div
                class="shadeTop"
                style="margin: 0 0.2604vw; position: relative"
                @click="getShop(item.name)"
              >
                <img class="img" :src="item.img" alt="" />
                <h3 style="padding: 1vw 0; font-size: 1.125rem">
                  {{ item.title }}
                  <p
                    style="
                      color: #3bb59e;
                      margin-top: 0.3125rem;
                      cursor: pointer;
                    "
                  >
                    进一步了解>>
                  </p>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- LiveApp -->
      <div class="substance2">
        <div class="warp">
          <h3 class="h3">应用场景<span>Actual scene</span></h3>
          <el-tabs class="top" v-model="substance2.activeName">
            <el-tab-pane
              v-for="item in substance2.user"
              :key="item.id"
              :label="item.title"
            >
              <el-tabs
                style="margin: 1.25rem 0 0 0"
                :tab-position="'right'"
                class="hidden-sm-and-down"
              >
                <el-tab-pane
                  v-for="list in item.data"
                  :key="list.id"
                  :label="list.title"
                >
                  <div class="scene">
                    <img :src="list.img" alt="" />
                    <div style="margin-top: 3.125rem">
                      <h3>{{ list.title }}</h3>
                      <span
                        style="margin: 0.625rem 0 1.125rem 0; display: block"
                        >{{ list.translate }}</span
                      >
                      <p style="font-size: 0.875rem">{{ list.content }}</p>
                      <!-- <el-button
                        @click="getList(item)"
                        style=""
                        class="busButton"
                        >MORE</el-button
                      > -->
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
              <div
                class="hidden-md-and-up"
                v-for="list in item.data"
                :key="list.id"
              >
                <div class="scenePhone">
                  <img style="width: 100%" :src="list.img" alt="" />
                  <div style="" class="detail">
                    <h3>{{ list.title }}</h3>
                    <!-- <span style="color: #3bb59e; font-size: 0.875rem"
                      >近一步了解>></span
                    > -->
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <!-- About Us -->
      <div class="substance3">
        <div class="warp">
          <h3 class="h3">关于我们<span>About Us</span></h3>
          <div class="AboutUs">
            <el-row>
              <el-col :md="12"
                ><div class="grid-content bg-purple">
                  <div class="AboutUsTop">
                    <p style="font-size: 0.875rem">
                      宇起数字科技（上海）有限公司，始创于2017年，宇起专注于IOT物联网平台、智能硬件、智能家居等领域。“宇你一起，享受智能生活”是宇起数字一直铭记于心的理念，始终以艺术品的审美标准，以用户为中心，以不断创新的精神，研发各类智能指纹产品及应用，提供安全便捷的用户体验。<br />
                      宇起数字力求将智能设备开发与场景化应用密切结合，在保证用户隐私安全的基础上，同时不断优化用户体验，提升产品的品质，我们一直相信精品永远属于那些将技术、便捷、简易、美观做到极致的产品。宇起数字智能设备已逐步深入到智能家居、智慧办公、智慧楼宇、智慧校园、智慧社区、智慧物业、智慧医疗等多个板块。宇起数字已研发出智能密码器、智能指纹箱包锁、智能指纹办公抽屉柜锁、智能指纹锁等系列产品。
                      <!-- 宇起智能设备是由APP+智能设备+控制终端组成的物联网智能设备。通过云端管理，实现在PC端、移动端、远程和现场操控智能设备和场景的切换。作为少数具备智能设备一体化自主研发实力技术、自主掌握指纹核心算法的驱动型企业，宇起数字拥有十余项技术专利，安全技术、无线网技术、移动通讯等物联网技术，实现硬件与软件、系统平台与手机客户端APP应用集成，组建智能物联网平台。 -->
                    </p>
                    <el-button
                      @click="$router.push({ path: '/synopsis' })"
                      class="busButton"
                      >MORE</el-button
                    >
                    <ul style="display: flex; margin: 1.0417rem 0">
                      <li>
                        <img
                          src="../../assets/mock/aboutUs/组 875.png"
                          alt=""
                        /><br />
                        <span>高端品质</span>
                      </li>
                      <li>
                        <img
                          src="../../assets/mock/aboutUs/组 878.png"
                          alt=""
                        /><br />

                        <span>智能物联</span>
                      </li>
                      <li>
                        <img
                          src="../../assets/mock/aboutUs/组 880.png"
                          alt=""
                        /><br />

                        <span>创新科技</span>
                      </li>
                      <li>
                        <img
                          src="../../assets/mock/aboutUs/组 881.png"
                          alt=""
                        /><br />

                        <span>隐私安全</span>
                      </li>
                    </ul>
                  </div>
                </div></el-col
              >
              <el-col :md="12"
                ><div class="grid-content bg-purple-light">
                  <div style="overflow: hidden">
                    <img
                      class="usImg"
                      style="width: 100%"
                      src="../../assets/mock/aboutUs/组 1138.png"
                      alt=""
                    />
                  </div></div
              ></el-col>
            </el-row>
          </div>
        </div>
      </div>
      <!-- Service support -->
      <div class="substance4">
        <div class="warp">
          <h3 class="h3">服务与支持<span>Service support</span></h3>
          <el-button class="busButton" @click="$router.push({ path: '/serve' })"
            >MORE</el-button
          >
          <div>
            <div class="substance4Top">
              <div>
                <ul>
                  <li style="background-color: #3db49e">
                    <img
                      src="../../assets/mock/BackgroundImg/组 18@2x.png"
                      alt=""
                    />
                    <h3>经销商加盟</h3>
                    <p>加盟热线021-55220166</p>
                  </li>
                  <li>
                    <img
                      src="../../assets/mock/BackgroundImg/组 906@2x.png"
                      alt=""
                    />
                    <h3>服务承诺</h3>
                    <p>免费送货，一年保修全程安装咨询</p>
                  </li>
                </ul>
                <ul>
                  <li>
                    <img
                      src="../../assets/mock/BackgroundImg/组 904@2x.png"
                      alt=""
                    />
                    <h3>7天无理由退货</h3>
                    <p>自收到购买商品7天内无理由退货</p>
                  </li>
                  <li style="background-color: #3db49e">
                    <img
                      src="../../assets/mock/BackgroundImg/组 905@2x.png"
                      alt=""
                    />
                    <h3>30天免费换货</h3>
                    <p>购买收货30天内，因质量问题，免费换货</p>
                  </li>
                </ul>
              </div>
              <div class="img">
                <img
                  class="hidden-sm-and-down"
                  style="margin-left: 6.25rem"
                  src="../../assets/mock/BackgroundImg/蒙版组 26.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- NEWS CENTER -->
      <div class="substance5">
        <div class="warp hidden-sm-and-down">
          <h3 class="h3">新闻资讯<span>NEWS CENTER</span></h3>
          <div>
            <el-tabs v-model="active">
              <el-tab-pane label="行业新闻" name="0">
                <div class="new">
                  <div
                    class="newLeft"
                    @click="getNewTo(substance5.article_id)"
                    style="background-color: #1e1e1e"
                  >
                    <div>
                      <img width="100%" :src="substance5.src" alt="" />
                    </div>
                    <h3>{{ substance5.title }}</h3>
                    <p>{{ substance5.description }}</p>
                  </div>
                  <div class="newRight">
                    <ul v-for="item in substance6" :key="item.article_id">
                      <li @click="getNewTo(item.article_id)">
                        <div>
                          <img :src="item.src" alt="" />
                        </div>
                        <div>
                          <h4>{{ item.title }}</h4>
                          <p style="color: #999">{{ item.description }}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="企业新闻" name="1">
                <div class="new">
                  <div
                    class="newLeft"
                    @click="getNewTo(enterprise.article_id)"
                    style="background-color: #1e1e1e"
                  >
                    <div>
                      <img width="100%" :src="enterprise.src" alt="" />
                    </div>
                    <h3>{{ enterprise.title }}</h3>
                    <p>{{ enterprise.description }}</p>
                  </div>
                  <div class="newRight">
                    <ul v-for="item in enterprise2" :key="item.article_id">
                      <li @click="getNewTo(item.article_id)">
                        <div><img :src="item.src" alt="" /></div>
                        <div>
                          <h4>{{ item.title }}</h4>
                          <p style="color: #999">{{ item.description }}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <el-button
              @click="$router.push('/new')"
              style="margin: 3.25rem auto"
              class="busButton"
              >MORE</el-button
            >
          </div>
        </div>

        <div class="warp hidden-md-and-up">
          <h3 class="h3">新闻资讯<span>NEWS CENTER</span></h3>
          <div>
            <el-tabs v-model="active">
              <el-tab-pane label="行业新闻" name="0">
                <div class="new">
                  <div class="newRight">
                    <ul v-for="item in substance6" :key="item.article_id">
                      <li @click="getNewTo(item.article_id)">
                        <div>
                          <img :src="item.src" alt="" />
                        </div>
                        <div>
                          <h4>{{ item.title }}</h4>
                          <p style="color: #999">{{ item.description }}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="企业新闻" name="1">
                <div class="new">
                  <div class="newRight">
                    <ul v-for="item in enterprise2" :key="item.article_id">
                      <li @click="getNewTo(item.article_id)">
                        <div><img :src="item.src" alt="" /></div>
                        <div>
                          <h4>{{ item.title }}</h4>
                          <p style="color: #999">{{ item.description }}</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <el-button
              @click="$router.push('/new')"
              style="margin: 3.25rem auto"
              class="busButton"
              >MORE</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import { getBanner, getNew, getQYNews } from "../../api/home/index";
export default {
  name: "Home",
  components: {
    HelloWorld,
  },
  data() {
    return {
      isActive: 0,
      owsTop: {},
      owsTop2: [
        {
          id: 0,
          title: "智能指纹密码器",
          product: "Y01A",
          product1: "Y01A",
          content: "指纹一触登录，密码一站式管理",
          imageUrl: require("../../assets/mock/bg/0.jpg"),
        },
        {
          id: 1,
          imageUrl: require("../../assets/mock/bg/1.jpg"),
          title: "智能指纹办公抽屉柜锁",
          product: "Y03B",
          product1: "Y03B",
          content: "告别钥匙烦恼，让办公更智能、更高效",
        },
        {
          id: 2,
          imageUrl: require("../../assets/mock/bg/2.jpg"),
          title: "智能指纹柜锁",
          product: "Y02B",
          product1: "Y02B",
          content: "手指一触即开，极速便捷",
        },
        {
          id: 3,
          imageUrl: require("../../assets/mock/bg/3.jpg"),
          title: "办公三联柜应用场景",
          product: "Y03B",
          content: "智能指纹办公抽屉柜锁",
        },
        {
          id: 4,
          imageUrl: require("../../assets/mock/bg/4.jpg"),
          title: "储物柜应用场景",
          product: "Y02B",
          content: "智能指纹柜锁",
        },
      ],
      substance1: [
        {
          id: 1,
          img: require("../../assets/mock/index/13.png"),
          title: "智能指纹密码器Y01A",
          name: "Y01A",
        },
        {
          id: 2,
          img: require("../../assets/mock/index/11.png"),
          title: "智能指纹办公抽屉柜锁Y03B",
          name: "Y03B",
        },
        {
          id: 3,
          img: require("../../assets/mock/index/12.png"),
          title: "智能指纹办公抽屉柜锁Y03L",
          name: "Y03L",
        },
      ],
      substance2: {
        activeName: "0",
        user: [
          {
            id: "00",
            title: "智能指纹密码器",
            data: [
              {
                id: "00",
                img: require("../../assets/mock/scene/蒙版组 26.png"),
                title: "密码一站式管理",
                // translate: "Staff locker",
                content:
                  "智能指纹密码器主要提供“密码APP一站式管理”指纹快速登录电脑、电脑网站及电脑客户端账户等服务。",
              },
              {
                id: "01",
                img: require("../../assets/mock/scene/登录电脑.png"),
                title: "登录电脑",
                // translate: "Staff locker",
                content:
                  "智能指纹密码器主要提供“密码APP一站式管理:指纹快速登录电脑、电脑网站及电脑客户端账户等服务。",
              },
              {
                id: "02",
                img: require("../../assets/mock/scene/登录网页账户.png"),
                title: "登录电脑网站",
                // translate: "Staff locker",
                content:
                  "智能指纹密码器主要提供“密码APP一站式管理:指纹快速登录电脑、电脑网站及电脑客户端账户等服务。",
              },

              {
                id: "03",
                img: require("../../assets/mock/scene/登录电脑客户端.png"),
                title: "登录电脑客户端",
                // translate: "Staff locker",
                content:
                  "智能指纹密码器主要提供“密码APP一站式管理:指纹快速登录电脑、电脑网站及电脑客户端账户等服务。",
              },
            ],
          },
          {
            id: "01",
            title: "企业办公柜锁",
            data: [
              {
                id: "01",
                img: require("../../assets/mock/scene/矩形 2939.png"),
                title: "办公三联柜",
                // translate: "Staff locker",
                content:
                  "宇起智能指纹锁为企业办公用柜提供了专业的解决方案，不仅方便了员工的使用，还方便了企业管理员的管理，我们全力为企业用户提供安全、便捷、智能的工作方式。",
              },
              {
                id: "02",
                img: require("../../assets/mock/scene/矩形 2944.png"),
                title: "办公抽屉柜",
                // translate: "Enterprise file cabinet",
                content:
                  "宇起智能指纹锁为企业办公用柜提供了专业的解决方案，不仅方便了员工的使用，还方便了企业管理员的管理，我们全力为企业用户提供安全、便捷、智能的工作方式。",
              },
              {
                id: "03",
                img: require("../../assets/mock/scene/矩形2947.png"),
                title: "办公文件柜",
                // translate: "Enterprise file cabinet",
                content:
                  "宇起智能指纹锁为企业办公用柜提供了专业的解决方案，不仅方便了员工的使用，还方便了企业管理员的管理，我们全力为企业用户提供安全、便捷、智能的工作方式。",
              },
            ],
          },
          {
            id: "02",
            title: "家用柜锁",
            data: [
              {
                id: "01",
                img: require("../../assets/mock/scene/矩形 2938.png"),
                title: "家用床头锁柜",
                // translate: "Study scene",
                content:
                  " 宇起智能指纹锁为家庭提供了全套智能锁解决方案，我们家庭用的智能指纹门锁、衣柜、抽屉柜、客厅电视柜、床头柜等等所有木质柜都可以用上我们宇起的智能指纹锁，回家手指轻轻一点，开启所有的锁，告别钥匙时代",
              },
              {
                id: "02",
                img: require("../../assets/mock/scene/矩形 2941.png"),
                title: "家用办公锁柜",
                // translate: "Bedroom scene",
                content:
                  "宇起智能指纹锁为家庭提供了全套智能锁解决方案，我们家庭用的智能指纹门锁、衣柜、抽屉柜、客厅电视柜、床头柜等等所有木质柜都可以用上我们宇起的智能指纹锁，回家手指轻轻一点，开启所有的锁，告别钥匙时代。",
              },
              {
                id: "03",
                img: require("../../assets/mock/scene/矩形 2945.png"),
                title: "家用储物锁柜",
                // translate: "Living room scene",
                content:
                  "宇起智能指纹锁为家庭提供了全套智能锁解决方案，我们家庭用的智能指纹门锁、衣柜、抽屉柜、客厅电视柜、床头柜等等所有木质柜都可以用上我们宇起的智能指纹锁，回家手指轻轻一点，开启所有的锁，告别钥匙时代。",
              },
            ],
          },
          {
            id: "03",
            title: "更多场景",
            data: [
              {
                id: "01",
                img: require("../../assets/mock/scene/矩形 2943.png"),
                title: "宿舍储物柜",
                // translate: "Jewelry cabinet",
                content:
                  "宇起智能指纹锁适用于珠宝商场展柜、酒店用柜，极大的方便店铺管理员的管理，各种授权功能非常好用，还可以对柜内物品进行管理。",
              },
              {
                id: "02",
                img: require("../../assets/mock/scene/矩形 2946.png"),
                title: "珠宝展柜",
                // translate: "Jewelry Showcase",
                content:
                  "宇起智能指纹锁适用于珠宝商场展柜、酒店用柜，极大的方便店铺管理员的管理，各种授权功能非常好用，还可以对柜内物品进行管理。",
              },
            ],
          },
        ],
      },
      substance5: [],
      substance6: [],
      active: "0",
      enterprise: [],
      enterprise2: [],
    };
  },
  created() {
    this.newList();
    this.newlist2();
    this.handleChange(0);
  },
  methods: {
    handleChange(index) {
      this.isActive = index;
    },
    // 背景图
    Banner() {
      getBanner().then((res) => {
        this.owsTop = res.data.owsTop;
      });
    },
    // 新闻资讯
    newList() {
      getNew().then((res) => {
        (this.substance5 = res.data.code[0]),
          (this.substance6 = res.data.code.slice(0, 4));
      });
    },
    // 企业新闻
    newlist2() {
      getQYNews().then((res) => {
        this.enterprise = res.data.code[0];
        this.enterprise2 = res.data.code.slice(1);
      });
    },
    getShop(name) {
      this.$router.push({ path: name });
    },
    getShopp(item) {
      this.$router.push({ path: item.product });
    },
    getNewTo(id) {
      this.$router.push({ path: "/newAbout", query: { id } });
    },
    getList(item) {
      if (item.id == "01") {
        this.$router.push({ path: "/household" });
      } else if (item.id == "02") {
        this.$router.push({ path: "/mores" });
      } else {
        this.$router.push({ path: "/work" });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.h3 {
  margin: 4.1667vw 0 2vw 0;
  span {
    font-size: 0.75rem;
    font-weight: 400;
    margin-left: 1.0833vw;
  }
}
.busButton {
  font-size: 14px;
  display: block;
  text-align: center;
  margin-top: 34px;
  width: 84px;
  height: 40px;
  color: #3cb59e;
  border: 1px solid #3cb59e;
  border-radius: 24px;
  background-color: transparent;
}
.busButton:hover {
  background-color: #3cb59e;
  color: #fff;
}
.carousel {
  position: absolute;
  // top: 16vw;
  left: 7vw;
  font-size: 2.6042vw;
  color: #fff;
  font-size: 2.6042vw;
  top: 16vw;
}

.substance1 {
  background: url("../../assets/mock/BackgroundImg/4.png") no-repeat;
  .product {
    box-sizing: border-box;
    text-align: center;
    display: flex;
    overflow: auto;
    .recommend {
      margin: 0 0.3125rem;
    }
    .shadeTop {
      min-width: 12.5rem;
      background: linear-gradient(140deg, #343c46 0%, #11151e 100%);
      opacity: 0.9;
      overflow: hidden;
      margin-bottom: 0.7813vw;
      padding: 20px 30px;
      box-sizing: border-box;
      .img {
        width: 80%;
        margin: 1.25rem 0;
        object-fit: cover;
        transition: all 0.3s;
      }
      img:hover {
        transform: scale(1.1);
      }
    }
    .shade {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      img {
        width: 6.25rem;
      }
    }
  }
}
.substance2 {
  background: url("../../assets/mock/BackgroundImg/3.png") no-repeat;
  .substance2Top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .scene {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    background-color: #171717;
    padding: 1.042vw;
    img {
      width: 34.375vw;
      margin: 2.0417vw;
      transition: all 0.3s;
    }
    img:hover {
      transform: scale(1.05);
    }

    div {
      text-align: left;
    }
  }
  .scenePhone {
    position: relative;
    margin: 1.3021vw;
    img {
      display: block;
    }
    .detail {
      position: absolute;
      width: 100%;
      color: #000000;
      text-align: center;
      bottom: 0;
      left: 0;
      overflow: hidden;
      background: rgba($color: #f2efef, $alpha: 0.8);
      padding: 2.0417vw 0;
      h3 {
        margin: 2.0417vw 0;
      }
    }
  }
}
.substance3 {
  background: url("../../assets/mock/BackgroundImg/5.png") no-repeat;
  .AboutUs {
    .AboutUsTop {
      padding: 0 1.875rem 1.875rem 0;
      .el-button {
        display: block;
        margin: 3.6042vw 0;
      }
      ul {
        li {
          text-align: center;
          margin-right: 3.5vw;
        }
      }
    }
    .usImg {
      transition: all 0.3s;
    }
    .usImg:hover {
      transform: scale(1.1);
    }
  }
}
.substance4 {
  background-color: #181818;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  color: #fff;
  .substance4Top {
    margin-bottom: 1.875rem;
    margin-top: 2.1875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      display: flex;
      justify-content: center;
      li {
        width: 100%;
        border: 1px solid #fff;
        padding: 4.4792vw 3.2292vw;

        box-sizing: border-box;
        text-align: center;
        h3 {
          font-size: 1rem;
          margin: 0.625rem 0;
        }
        p {
          font-size: 0.875rem;
        }
      }
    }
    .img {
      width: 50%;
      img {
        width: 39vw;
        // width: 26.0417vw;
      }
    }
  }
}
.substance5 {
  background: url("../../assets/mock/BackgroundImg/7.png") no-repeat;
  ::v-deep .el-tabs__nav-scroll {
    float: right;
  }
  .new {
    display: flex;
    justify-content: space-between;
    .newLeft {
      overflow: hidden;
      margin: 0.625rem 0.625rem 0 0.625rem;
      flex: 6;
      img {
        transition: all 0.3s;
      }
      img:hover {
        transform: scale(1.1);
      }
      h3,
      p {
        margin: 1.5rem 0 0 1.5rem;
      }
      p {
        font-size: 0.75rem;
        color: #999;
      }
    }
    .newRight {
      flex: 4;
      ul {
        li {
          height: 8.25rem;
          display: flex;
          background-color: #1e1e1e;
          margin: 10px 0 0 10px;
          transition: all 0.6s;
          img {
            width: 10.6875rem;
            height: 5.9375rem;
            margin: 0.625rem;
            display: block;
            object-fit: cover;
          }
          font-size: 0.75rem;
          h4 {
            margin: 0.625rem;
          }
          p {
            margin: 0.625rem;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        li:hover {
          transform: scale(1.04);
        }
      }
    }
  }
}
.substance1,
.substance2,
.substance3,
.substance5 {
  background-size: cover;
  background-position: center;
  overflow: hidden;
  color: #fff;
  padding-bottom: 2.6042vw;
}
::v-deep .el-tabs__item {
  color: #fff;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs--right .el-tabs__nav-wrap.is-right::after {
  width: 0;
}
::v-deep .el-tabs--right .el-tabs__active-bar.is-right {
  width: 0;
}
@media only screen and (min-width: 500px) {
  ::v-deep .el-tabs__nav-scroll {
    float: right;
  }
}

::v-deep .el-carousel__arrow {
  transform: scale(1.2);
  margin: 0 0.5208vw;
}
::v-deep .el-icon-arrow-left {
  font-size: 1.25rem;
}
::v-deep .el-icon-arrow-right {
  font-size: 1.25rem;
}
::v-deep .el-tabs__item.is-right {
  height: 3.75rem;
  line-height: 3.75rem;
}
::v-deep .el-tabs__item.is-right.is-active {
  color: #3cb59e;
  background-color: #191919;
}
::v-deep .el-tabs--right .el-tabs__header.is-right {
  margin-left: 0;
}
// ::-webkit-scrollbar {
//      display: none;
//   }
</style>

<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    
    this.detectAndRedirect();
  },
  methods: {
    detectAndRedirect() {
      console.log(navigator.userAgent);  
      // 判断用户设备
      const isIOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      if (isIOS) {
        // iOS设备，跳转到iOS链接
        window.location.href =
          "https://apps.apple.com/cn/app/%E5%AE%87%E8%B5%B7/id1463130217";
      } else {
        // 其他设备（Android等），跳转到Android链接
        window.location.href =
          "https://sj.qq.com/appdetail/com.example.dell.xiaoyu";
      }
    },
  },
};
</script>

<style></style>

<template>
  <div>
    <div class="container">
      <div class="warp content">
        <el-row
          type="flex"
          class="row-bg hidden-md-and-down"
          justify="space-between"
          style="
            padding-bottom: 1.25rem;
            position: inherit;
            border-bottom: 1px solid #363636;
          "
        >
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <h4>产品中心</h4>
              <P v-for="(item, index) in product" :key="index">
                {{ item }}
              </P>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple-light">
              <h4>联系我们</h4>
              <p
                style="width: 18.75rem"
                v-for="(item, index) in relation"
                :key="index"
              >
                {{ item }}
              </p>
            </div></el-col
          >
          <el-col :span="6"
            ><div class="grid-content bg-purple">
              <h4>关注我们</h4>
              <div style="display: flex; text-align: center">
                <div>
                  <p>Android、ios下载</p>
                  <img
                    style="width: 6.25rem"
                    src="../../assets/QRcode/android.jpg"
                    alt=""
                  />
                </div>
                <div style="margin-left: 0.9375rem">
                  <p>微信公众号</p>
                  <img
                    style="width: 6.25rem"
                    src="../../assets/QRcode/Official.png"
                    alt=""
                  />
                </div>
              </div></div
          ></el-col>
        </el-row>
      </div>
      <div class="contentFoot hidden-lg-and-up">
        <el-collapse v-model="activeNames" @change="handleChange" accordion>
          <el-collapse-item title="产品中心" name="1">
            <div>
              <P v-for="(item, index) in product" :key="index">
                {{ item }}
              </P>
            </div>
          </el-collapse-item>
          <el-collapse-item title="联系我们" name="2">
            <div>
              <p
                style="width: 18.75rem"
                v-for="(item, index) in relation"
                :key="index"
              >
                {{ item }}
              </p>
            </div>
          </el-collapse-item>
          <el-collapse-item title="关注我们" name="3">
            <div>
              <div style="text-align: center">
                <div>
                  <p>Android、ios下载</p>
                  <img
                    style="width: 6.25rem"
                    src="../../assets/QRcode/android.jpg"
                    alt=""
                  />
                </div>
                <div>
                  <p>微信公众号</p>
                  <img
                    style="width: 6.25rem"
                    src="../../assets/QRcode/Official.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <div
        style="
          text-align: center;
          padding-bottom: 0.625rem;
          color: #858585;
          font-size: 0.75rem;
        "
      >
        <p>
          <img src="../../assets/gg.png" alt="" /> &nbsp;
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010102006642"
            target="_blank"
          >
            沪公网安备 31010102006642号
          </a>
          &nbsp; &nbsp;
          <a href="http://beian.miit.gov.cn/" target="_blank"
            >沪ICP备17026007号-4</a
          >
        </p>
        <p>Copyright@2017-2019 宇起数字科技（上海）有限公司版权所有</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      product: [
        "智能指纹密码器  Y01A",
        "智能指纹办公抽屉柜锁  Y03B",
        "智能指纹办公抽屉柜锁  Y03L",
      ],
      relation: {
        phone: "电话： 021-55220166",
        time: "工作时间： 周一至周五（9:00-18:00）",
        Email: "邮箱： yuqi@yuqidata.com",
        company: "公司： 宇起数字科技（上海）有限公司",
      },
      activeNames: ["0"],
    };
  },
  methods: {
    handleChange(val) {},
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding-top: 0.625rem;
  background-color: black;
  color: #fff;
  p {
    margin: 0.625rem 0;
    min-width: 9.5rem;
  }
  .content {
    // padding-bottom: 1.25rem;
  }
  .contentFoot {
    padding: 0 1.25rem;
    div {
      background-color: #000;
      color: #fff;
    }
  }
}
::v-deep .el-collapse-item__header {
  background-color: #000;
  border-bottom: 1px solid #363636;
  color: #fff;
}
::v-deep .el-collapse-item__wrap {
  background-color: #000;
  border-bottom: 1px solid #363636;
}
::v-deep .el-collapse {
  border-top: 1px solid #363636;
}
</style>
